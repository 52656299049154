





















































import { Component, Vue, Watch } from 'vue-property-decorator';
import { FiltersModel } from '../../models/filters/filters.model';
import DateFilter from './DateFilter/DateFilter.vue';
import {
  FilterInput,
  FilterConditions,
  TransformFiltersToJson
} from '@/shared/services/filter/filter.service';
import { DateType } from '../../models/filters/date-filter.model';
import moment from 'moment';
import {
  BS_DATEPICKER_FORMAT,
  convertDateArrayFormatFieldToAPIFormat,
  DATE_API_FORMAT
} from '@/utils/date.util';

import SelectionFilter from '@/shared/components/form/SelectionFilter.vue';

import { AccountingModule } from '@/store/index';
import { AccountingTypes } from '../../models/accounting.model';
import { createDivisionFilter } from '@/shared/services/division/division.helper';

@Component({
  components: { DateFilter, SelectionFilter }
})
export default class ApArFilters extends Vue {
  @Watch('$route', { immediate: true })
  onRouterChange() {
    this.search();
  }

  accountingModule = AccountingModule;
  form: any;
  excelData: any;

  fromFilter = new FilterInput({
    condition: FilterConditions.GreaterOrEqual,
    search: moment().subtract(12, 'days').format(BS_DATEPICKER_FORMAT),
    field: DateType.CreatedDate
  });

  toFilter = new FilterInput({
    condition: FilterConditions.LessThenOrEqual,
    search: moment().format(BS_DATEPICKER_FORMAT),
    field: DateType.CreatedDate
  });

  divisionFilter = createDivisionFilter();

  constructor() {
    super();
    this.form = new FiltersModel();
  }

  search() {
    this.fromFilter.search = convertDateArrayFormatFieldToAPIFormat(
      this.fromFilter.search,
      [BS_DATEPICKER_FORMAT, DATE_API_FORMAT]
    );

    this.toFilter.search = moment(this.toFilter.search, [
      BS_DATEPICKER_FORMAT,
      DATE_API_FORMAT
    ])
      .endOf('day')
      .format(DATE_API_FORMAT);

    this.accountingModule.setFilters(
      TransformFiltersToJson([
        this.toFilter,
        this.fromFilter,
        this.divisionFilter
      ])
    );

    this.accountingModule.search({
      acctType: AccountingTypes[this.$route.params.type]
    });
  }

  async excel() {
    this.accountingModule.setFilters(
      TransformFiltersToJson([this.toFilter, this.fromFilter])
    );

    await this.accountingModule.excel(AccountingTypes[this.$route.params.type]);

    if (this.accountingModule.excelData) {
      window.open(this.accountingModule.excelData.url);
    }
  }

  resetFilters() {
    this.fromFilter = new FilterInput({
      condition: FilterConditions.GreaterOrEqual,
      search: moment().subtract(12, 'days').format(BS_DATEPICKER_FORMAT),
      field: DateType.CreatedDate
    });

    this.toFilter = new FilterInput({
      condition: FilterConditions.LessThenOrEqual,
      search: moment().format(BS_DATEPICKER_FORMAT),
      field: DateType.CreatedDate
    });

    this.accountingModule.setFilters(
      TransformFiltersToJson([
        this.toFilter,
        this.fromFilter,
        this.divisionFilter
      ])
    );
  }
}
